<template>
  <div class="flex flex-col" style="gap: 12px;">
    <p class="section-label">{{ displayText.pointName }}</p>
    <div class="grid grid-cols-2" style="gap: 12px; padding: 0 32px;">
      <div
        class="point-discount-item"
        :class="{ isSelected: isSelected({id: 'point-all'}), isDisabled: isDisabled({id: 'point-all'}) }"
        @click="onSelect('all')"
      >
        <span>{{ displayText.pointName }}</span>
        <span>全數折抵</span>
      </div>
      <div
        class="point-discount-item"
        :class="{ isSelected: isSelected({id: 'point-part'}), isDisabled: isDisabled({id: 'point-part'}) }"
        @click="onSelect('part')"
      >
        <span>{{ displayText.pointName }}</span>
        <span>部分折抵</span>
      </div>
    </div>

    <SalesPOSPointPartDiscountModal
      v-if="modal.partDiscount"
      scope="singleDiscount"
      :maxPrice="maxDiscountPrice"
      @confirm="onConfirmPartDiscount"
      @close="modal.partDiscount = false"
    />
  </div>
</template>

<script>
import { useSalesPOSCreate } from '@/use/useSalesPOS'
import { get, find } from 'lodash'
import { computed, defineComponent, reactive } from 'vue'
import SalesPOSPointPartDiscountModal from '@/components/SalesPOSPointPartDiscountModal.vue'
import { useShop } from '@/use/shop'

export default defineComponent({
  name: 'SalesPOSPointDiscountList',
  components: {
    SalesPOSPointPartDiscountModal,
  },
  setup (props, { emit }) {
    const { cart, getActiveCartItem, configData, computedUsedResource } = useSalesPOSCreate()
    const { defaultShopPoint } = useShop()

    const modal = reactive({
      partDiscount: false,
    })

    const displayText = computed(() => {
      const text = {
        pointName: get(defaultShopPoint.value, 'name', '點數'),
      }
      return text
    })

    const isSelected = computed(() => {
      return (item) => {
        const activeCartItem = getActiveCartItem()
        return Boolean(find(get(activeCartItem, 'discounts'), { id: item.id }))
      }
    })

    const isDisabled = computed(() => {
      return (item) => {
        if (!isSelected.value(item)) {
          const activeCartItem = getActiveCartItem()
          const remainingPrice = activeCartItem.getDiscountRemainingPrice()
          // 如果品項已無剩餘金額可折抵
          if (remainingPrice <= 0) {
            return true
          }
          // 如果已無剩餘點數可折抵
          const preUsedPoint = get(computedUsedResource.value, 'point', 0) // 目前已使用回饋金
          const memberPoint = get(configData, 'consumerData.pointBalance', 0)
          const remainingPointBalance = memberPoint - preUsedPoint
          if (!remainingPointBalance) {
            return true
          }
        }
      }
    })

    const maxDiscountPrice = computed(() => {
      const activeCartItem = getActiveCartItem()
      return activeCartItem.getDiscountRemainingPrice()
    })

    const onSelect = (type) => {
      const activeCartItem = getActiveCartItem()
      const isSelectedAll = isSelected.value({ id: 'point-all' })
      const isSelectedPart = isSelected.value({ id: 'point-part' })

      if (type === 'part') {
        if (isDisabled.value({ id: 'point-part' })) return
        if (isSelectedPart) {
          activeCartItem.removeSingleDiscount({ id: 'point-part' })
          return
        }
        modal.partDiscount = true
      } else if (type === 'all') {
        if (isDisabled.value({ id: 'point-all' })) return
        if (isSelectedAll) {
          activeCartItem.removeSingleDiscount({ id: 'point-all' })
          return
        }

        const preUsedPoint = get(computedUsedResource.value, 'point', 0)
        const memberPoint = get(configData, 'consumerData.pointBalance', 0)
        const pointDiscountRate = get(configData, 'pointDiscountRate', 1)
        const remainingPointBalance = memberPoint - preUsedPoint
        const remainingPrice = activeCartItem.getDiscountRemainingPrice()
        const maxSavePrice = Math.floor(remainingPointBalance / pointDiscountRate)

        let pointUse = 0
        let savePrice = 0

        if (remainingPrice >= maxSavePrice) {
          pointUse = maxSavePrice * pointDiscountRate
          savePrice = maxSavePrice
        } else {
          pointUse = remainingPrice * pointDiscountRate
          savePrice = remainingPrice
        }

        if (!pointUse) {
          window.$message.warning(`${displayText.value.pointName}餘額不足已折抵金額`)
          return
        }

        if (isSelectedPart) {
          activeCartItem.removeSingleDiscount({ id: 'point-part' })
        }

        activeCartItem.addSingleDiscount({
          id: 'point-all',
          name: `${displayText.value.pointName}折抵`,
          mustSpendSetting: {
            type: 'point',
            amount: pointUse,
          },
          discountSource: 'noSource',
          type: 'point',
          cashAmount: savePrice,
          balance: remainingPointBalance,
        })
      }
    }

    const onConfirmPartDiscount = (data) => {
      const activeCartItem = getActiveCartItem()
      const isSelectedAll = isSelected.value({ id: 'point-all' })
      if (isSelectedAll) {
        activeCartItem.removeSingleDiscount({ id: 'point-all' })
      }
      activeCartItem.addSingleDiscount({
        id: 'point-part',
        name: `${displayText.value.pointName}折抵`,
        mustSpendSetting: {
          type: 'point',
          amount: data.pointUsed,
        },
        discountSource: 'noSource',
        type: 'point',
        cashAmount: data.savePrice,
      })
    }

    return {
      isSelected,
      isDisabled,
      onSelect,
      modal,
      onConfirmPartDiscount,
      cart,
      maxDiscountPrice,
      displayText,
    }
  },
})
</script>

<style lang="postcss" scoped>
.section-label {
  @apply text-primary-100 text-normal font-bold;
}
.point-discount-item {
  @apply bg-gray-20 rounded-[6px] h-[88px] w-full;
  @apply flex flex-col items-center justify-center text-center;
  @apply select-none cursor-pointer;
  @apply border-[2px] border-transparent;
}
.isSelected {
  @apply border-primary-100 border-[2px] bg-white text-primary-100;
}

.isDisabled {
  @apply border-gray-20 bg-gray-20 cursor-not-allowed opacity-40;
}
</style>
