<template>
  <div class="flex items-center" style="gap: 6px">
    <p class="font-bold text-normal text-gray-100 flex-shrink-0 select-none">
      {{ displayText.tabTitle }}
    </p>
    <HorizontalTabs
      class="w-full"
      :tabs="displayTabs"
      :value.sync="context.categoryGroup"
      type="card"
      :tab-gap="0"
      @select="onSelectTab"
    />
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue'
import HorizontalTabs from '@/components/HorizontalTabs.vue'
import { map, get } from 'lodash'
import { useSalesPOSCreate } from '@/use/useSalesPOS'

export default defineComponent({
  name: 'SalesPOSCategoryGroupTab',
  components: {
    HorizontalTabs,
  },
  setup (props, { emit }) {
    const { context, filterCategoryMenu, filterProductGroupMenu, sortedMenus } = useSalesPOSCreate()

    const displayTabs = computed(() => {
      const dataList = sortedMenus.value.categoryGroup
      const showList = map(dataList, (item) => {
        return {
          label: get(item, 'name'),
          key: get(item, 'id'),
        }
      })

      showList.push({ label: '其他', key: null })

      return showList
    })

    const displayText = computed(() => {
      const text = {
        tabTitle: '類別群組',
      }
      return text
    })

    const onSelectTab = () => {
      if (context.categoryGroup === 'appointment') {
        context.category = null
        context.productGroup = null
      } else {
        context.category = get(filterCategoryMenu(context.categoryGroup), '[0].id', null)
        context.productGroup = get(filterProductGroupMenu(context.category), '[0].id', null)
      }
    }

    return {
      context,
      displayTabs,
      onSelectTab,
      displayText,
    }
  },
})
</script>

<style lang="postcss" scoped>
::v-deep(.el-tabs__header) {
  @apply !border-none bg-[#F6F7FB];
}

::v-deep(.el-tabs__nav) {
  @apply !border-none;
}

::v-deep(.el-tabs__item) {
  @apply !border-none text-normal leading-[20.27px] h-auto py-[8px] text-gray-80;
  @apply hover:text-primary-50;
}

::v-deep(.el-tabs__item.is-active) {
  @apply bg-white text-gray-80;
  @apply !border-b-[2px] !border-b-primary-100 !border-solid;
  border-left: 0px !important;
}
</style>
