<template>
  <div class="flex flex-col overflow-y-auto" style="gap: 12px;">
    <p class="font-bold text-normal text-gray-100" style="line-height: 35px;">單項折扣</p>
    <div class="sales-pos-item-discount-tab">
      <!-- <HorizontalTabs
        :tabs="[{label: '單項折扣', key: 'singleDiscount', value: 'singleDiscount' }]"
        value="singleDiscount"
        type="card"
        :tab-gap="0"
        class="bg-primary-10"
      /> -->

      <div style="gap: 24px" class="flex flex-col">
        <SalesPOSNormalDiscountList />

        <div class="grid-container">
          <SalesPOSCashbackDiscountList v-if="showControl.cashback" />
          <SalesPOSPointDiscountList v-if="showControl.point" />
          <SalesPOSCouponDiscountList v-if="showControl.coupon" />
          <SalesPOSClassTicketDiscountList v-if="showControl.classTicket" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue'
// import HorizontalTabs from '@/components/HorizontalTabs.vue'
import SalesPOSNormalDiscountList from './SalesPOSNormalDiscountList.vue'
import SalesPOSCashbackDiscountList from './SalesPOSCashbackDiscountList.vue'
import SalesPOSPointDiscountList from './SalesPOSPointDiscountList.vue'
import SalesPOSCouponDiscountList from './SalesPOSCouponDiscountList.vue'
import SalesPOSClassTicketDiscountList from './SalesPOSClassTicketDiscountList .vue'
import { useSalesPOSCreate } from '@/use/useSalesPOS'
import { usePermissions } from '@/use/permissions'
import { get } from 'lodash'

export default defineComponent({
  name: 'SalesPOSItemDiscountTab',
  components: {
    // HorizontalTabs,
    SalesPOSNormalDiscountList,
    SalesPOSPointDiscountList,
    SalesPOSCashbackDiscountList,
    SalesPOSCouponDiscountList,
    SalesPOSClassTicketDiscountList,
  },
  setup (props, { emit }) {
    const { context, configData, getActiveCartItem } = useSalesPOSCreate()
    const { checkAction } = usePermissions()
    const useFeatures = computed(() => {
      return {
        classTicket: checkAction('admin.classTicket.page'),
        coupon: checkAction('admin.coupon.page'),
      }
    })

    const showControl = computed(() => {
      const controls = {
        cashback: get(configData, 'enableCashbackDiscount'),
        point: get(configData, 'enablePointDiscount'),
        classTicket: false,
        coupon: useFeatures.value.coupon,
      }

      const avtiveCartItem = getActiveCartItem()

      if (avtiveCartItem.type === 'appointmentService') {
        if (useFeatures.value.classTicket) controls.classTicket = true
      }

      if (!get(context.member, 'id')) {
        controls.point = false
        controls.coupon = false
        controls.classTicket = false
        controls.cashback = false
      }

      return controls
    })

    return { showControl }
  },
})
</script>

<style lang="postcss" scoped>
.sales-pos-item-discount-tab {
  @apply bg-primary-10 flex-1 p-[12px] rounded-[6px];
}

.grid-container {
  @apply grid gap-[12px] grid-cols-2;
  /* grid-template-columns: repeat(auto-fill, minmax(100px, 357px)); */
}
</style>
