<template>
  <div class="sales-pos-member-info-block">
    <div class="flex items-center justify-between" style="gap: 6px; height: 32px">
      <div class="cursor-pointer" @click="toggleSidebar">
        <MaterialIcon size="20">menu</MaterialIcon>
      </div>

      <div
        class="flex items-center text-primary-100 cursor-pointer"
        style="gap: 4px"
        @click="onChangeMember"
      >
        <MaterialIcon size="20" color="var(--primary-100)">person_search</MaterialIcon>
        重輸號碼
      </div>
      <div
        class="flex items-center text-primary-100 cursor-pointer"
        style="gap: 4px"
        @click="onReset"
      >
        <MaterialIcon size="20" color="var(--primary-100)">refresh</MaterialIcon>
        重置明細
      </div>
    </div>

    <div class="flex flex-col cursor-pointer" style="gap: 6px" @click="goMemberProfile">
      <div class="flex items-center truncate" style="gap: 6px">
        <MaterialIcon size="20" color="var(--primary-100)">person_filled</MaterialIcon>
        <p class="truncate">{{ displayData.name }}</p>
      </div>
      <div v-if="showControl.phone || showControl.level" class="flex justify-between">
        <div v-if="showControl.phone" class="flex items-center" style="gap: 6px">
          <SVGIcon icon="common-phone" stroke="transparent" fill="var(--primary-100)" />
          <p>{{ displayData.phone }}</p>
        </div>
        <div v-if="showControl.level" class="flex items-center truncate" style="gap: 6px">
          <SVGIcon icon="common-level" stroke="white" fill="var(--primary-100)" />
          <p class="truncate">{{ displayData.levelName }}</p>
        </div>
      </div>
    </div>

    <div
      v-if="showControl.moreInfo"
      class="flex justify-between select-none cursor-pointer text-primary-100"
      @click="expand.moreInfo = !expand.moreInfo"
    >
      <span class="text-sub">更多資訊</span>
      <MaterialIcon :size="20">
        {{
          expand.moreInfo ? ' keyboard_arrow_up' : ' keyboard_arrow_down'
        }}
      </MaterialIcon>
    </div>

    <el-collapse-transition>
      <div v-show="expand.moreInfo" class="flex flex-col" style="gap: 6px">
        <div
          v-for="resource in displayResourceFields"
          :key="resource.label"
          class="text-sub truncate"
        >
          <span>{{ resource.label }}</span>：<span class="text-primary-100 truncate">{{ resource.value }}</span>
        </div>
      </div>
    </el-collapse-transition>
  </div>
</template>

<script>
import { computed, defineComponent, reactive } from 'vue'
import { get, filter, isNull, find } from 'lodash'
import { useSalesPOSCreate } from '@/use/useSalesPOS'
import { useRouter } from 'vue-router/composables'
import { usePermissions } from '@/use/permissions'
import store from '@/store'
import { useShop } from '@/use/shop'

export default defineComponent({
  name: 'SalesPOSMemberInfoBlock',
  setup (props, { emit }) {
    const router = useRouter()
    const { configData, reset, context, cart } = useSalesPOSCreate()
    const { shopPointList } = useShop()
    const { checkAction } = usePermissions()

    const useFeatures = computed(() => {
      return {
        usePoint: checkAction('admin.shopPoint.page'),
        useChashback: checkAction('admin.shopCashback.page'),
        useClassTicket: checkAction('admin.classTicket.page'),
        useCoupon: checkAction('admin.coupon.page'),
      }
    })

    const expand = reactive({
      moreInfo: true,
    })

    const displayData = computed(() => {
      const consumerData = configData.consumerData
      let userName
      let userPhone
      let levelName

      if (get(context.member, 'id')) {
        userName = get(consumerData, 'userInfo.name')
        userPhone = get(consumerData, 'userInfo.phone')
        const memberLevel = get(consumerData, 'member.level')
        levelName = get(configData.memberConfig, `levels.${memberLevel}.name`)
      } else {
        userName = get(context.member, 'name')
        userPhone = get(context.member, 'phone')
      }
      return {
        name: userName,
        phone: userPhone,
        levelName,
        resources: {
          cashback: get(consumerData, 'cashbackBalance'), // 回饋金
          wallet: get(consumerData, 'walletBalance'), // 儲值金
          coupon: get(consumerData, 'couponRecords'), // 票券
          point: get(consumerData, 'pointBalance'), // 點數
        },
      }
    })

    const displayResourceFields = computed(() => {
      const omitList = []

      const defaultPoint = find(shopPointList.value, { isDefault: true })

      const dields = [
        { label: '儲值金', value: displayData.value.resources.wallet },
        { label: '回饋金', value: displayData.value.resources.cashback },
        { label: '票券', value: get(displayData.value.resources.coupon, 'length') },
        { label: get(defaultPoint, 'name', '點數'), value: displayData.value.resources.point },
      ]

      if (isNull(displayData.value.resources.point)) omitList.push('點數')
      if (isNull(displayData.value.resources.cashback)) omitList.push('回饋金')
      if (isNull(displayData.value.resources.coupon)) omitList.push('票券')
      if (isNull(displayData.value.resources.wallet)) omitList.push('儲值金')

      if (!get(context, 'member.id')) {
        return []
      }

      return filter(dields, (field) => !omitList.includes(field.label))
    })

    const showControl = computed(() => {
      const controls = {
        phone: true,
        level: true,
        moreInfo: true,
      }

      if (!get(context, 'member.id') && !get(context, 'member.phone')) {
        controls.phone = false
        controls.level = false
        controls.moreInfo = false
      }

      if (!get(context, 'member.id')) {
        controls.moreInfo = false
        controls.level = false
      }

      return controls
    })

    const goMemberProfile = () => {
      if (!get(context, 'member.id')) return

      router.push({
        name: 'MemberProfile',
        params: { id: get(context, 'member.id') },
        query: { tab: 'info' },
      })
    }

    const toggleSidebar = () => {
      store.dispatch('app/toggleSidebar')
    }

    const onReset = () => {
      cart.value = []
      context.payments = []
      context.orderDiscount = {}
    }
    const onChangeMember = () => {
      router.push({ name: 'SalesPOSEntry' })
    }
    return {
      expand,
      displayData,
      displayResourceFields,
      onReset,
      onChangeMember,
      goMemberProfile,
      toggleSidebar,
      showControl,
    }
  },
})
</script>

<style lang="postcss" scoped>
.sales-pos-member-info-block {
  @apply p-[16px] overflow-hidden;
  @apply flex flex-col gap-[6px];
}
</style>
