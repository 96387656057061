<template>
  <BaseDialog title="編輯單價與數量" @confirm="onConfirm" @close="$emit('close')">
    <div>
      <BaseElForm ref="formRef" :model="formData" :rules="formRules">
        <BaseElFormItem label="編輯單價" prop="price">
          <ElInputWrapper>
            <BaseElInput
              v-model="formData.price"
              style="width: 370px;"
              :disabled="disabledControls.editPrice"
            />
          </ElInputWrapper>
        </BaseElFormItem>

        <BaseElFormItem label="數量" prop="quantity">
          <ElInputWrapper>
            <BaseElInputNumber
              v-model="formData.quantity"
              :step="1"
              :min="1"
              step-strictly
              style="width: 370px;"
            />
          </ElInputWrapper>
        </BaseElFormItem>
      </BaseElForm>
    </div>
  </BaseDialog>
</template>

<script>
import { computed, defineComponent, onBeforeMount, ref } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { useBaseForm } from '@/use/useForm'
import { integerRules, maxNumberRules, minRules, noEmptyRules } from '@/validation'
import { isUndefined, get } from 'lodash'
import { useSalesPOSCreate } from '@/use/useSalesPOS'
import ElInputWrapper from '@/components/ElInputWrapper.vue'

export default defineComponent({
  name: 'SalesPOSEditProductItemPriceModal',
  components: {
    BaseDialog,
    ElInputWrapper,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const { pruneInvalidSingleDiscount, computedUsedResource } = useSalesPOSCreate()
    const { formRef, formData, initFormData, checkForm, loading } = useBaseForm()
    initFormData({
      price: 0,
      quantity: 0,
    })

    const lastUsed = ref(0)

    const formRules = computed(() => {
      const rules = {
        price: [noEmptyRules(), minRules(0), integerRules()],
        quantity: [noEmptyRules()],
      }

      if (get(props.data, 'useClassTicketRecord')) {
        const availableUseTimes = get(props.data, 'classTicketRecord.availableUseTimes')
        const classTicketRecord = get(props.data, 'classTicketRecord')
        const usedTimes = get(computedUsedResource.value.classTicketRecord, classTicketRecord.id, 0) - get(props.data, 'quantity', 0)
        rules.quantity.push(minRules(1), maxNumberRules(availableUseTimes - usedTimes))
      }

      return rules
    })

    const disabledControls = computed(() => {
      const disabled = {
        editPrice: false,
      }

      if (get(props.data, 'useClassTicketRecord')) {
        disabled.editPrice = true
      }

      return disabled
    })

    const onConfirm = async () => {
      if (loading.value) return
      loading.value = true
      const pass = await checkForm(formRef.value)
      if (!pass) {
        loading.value = false
        return
      }

      props.data.changePrice(formData.price)
      props.data.changeQuantity(formData.quantity)
      pruneInvalidSingleDiscount(props.data)

      emit('close')
    }

    onBeforeMount(() => {
      const customPrice = props.data.customPrice
      const defaultPrice = props.data.defaultPrice
      let price = defaultPrice
      if (!isUndefined(customPrice)) {
        price = customPrice
      }

      initFormData({
        price,
        quantity: props.data.quantity,
      })
    })

    return { formRef, formData, formRules, onConfirm, disabledControls, computedUsedResource }
  },
})
</script>

<style lang="postcss" scoped>

</style>
