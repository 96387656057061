import { render, staticRenderFns } from "./SalesPOSEditProductItemPriceModal.vue?vue&type=template&id=66998847&scoped=true"
import script from "./SalesPOSEditProductItemPriceModal.vue?vue&type=script&lang=js"
export * from "./SalesPOSEditProductItemPriceModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66998847",
  null
  
)

export default component.exports