import { render, staticRenderFns } from "./SalesPOSCashbackDiscountList.vue?vue&type=template&id=9588904e&scoped=true"
import script from "./SalesPOSCashbackDiscountList.vue?vue&type=script&lang=js"
export * from "./SalesPOSCashbackDiscountList.vue?vue&type=script&lang=js"
import style0 from "./SalesPOSCashbackDiscountList.vue?vue&type=style&index=0&id=9588904e&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9588904e",
  null
  
)

export default component.exports