<template>
  <div class="flex items-center" style="gap: 6px">
    <p class="font-bold text-normal text-gray-100 flex-shrink-0 select-none">
      {{ displayText.tabTitle }}
    </p>
    <template v-if="showControl.tabs">
      <HorizontalTabs
        class="w-full"
        :tabs="displayTabs"
        :value.sync="context.salesUnitGroup"
        type="card"
        :tab-gap="0"
      />
    </template>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue'
import HorizontalTabs from '@/components/HorizontalTabs.vue'
import { useSalesPOSCreate } from '@/use/useSalesPOS'
import { map, get, filter, find } from 'lodash'
import { sortOrder } from '@/utils/helper'

export default defineComponent({
  name: 'SalesPOSSalesUnitGroupTab',
  components: {
    HorizontalTabs,
  },
  setup (props, { emit }) {
    const { context, configData, sortedMenus } = useSalesPOSCreate()
    const displayTabs = computed(() => {
      const dataList = filter(get(configData, 'menu.salesGroupMenus', []), {
        salesGroup: { type: 'salesUnit' },
      })
      const data = sortOrder(map(dataList, 'salesGroup'))
      data.push({ name: '其他', id: null })
      const showList = map(data, (item) => ({
        label: item.name,
        key: item.id,
      }))

      return showList
    })

    const isRequired = computed(() => {
      const product = find(sortedMenus.value.product, { id: context.product })
      return get(product, 'needUnit')
    })

    const displayText = computed(() => {
      const text = {
        tabTitle: '銷售人員群組',
      }

      if (!showControl.value.tabs) {
        text.tabTitle = '銷售人員'
      }

      return text
    })

    const showControl = computed(() => {
      const controls = {
        tabs: true,
      }

      if (!get(filter(displayTabs.value, (item) => item.key), 'length')) {
        controls.tabs = false
      }

      return controls
    })

    return {
      context,
      displayTabs,
      displayText,
      showControl,
      isRequired,
    }
  },
})
</script>

<style lang="postcss" scoped></style>
