<template>
  <div class="sales-pos-sales-unit-list">
    <swiper
      v-if="loaded && displayData.length"
      class="w-full h-full"
      navigation
      pagination
      centeredSlides
      :loop="false"
    >
      <swiper-slide v-for="(chunk, chunkIdx) in displayData" :key="chunkIdx">
        <div class="sales-unit-list-chunk ">
          <div
            v-for="salesUnit in chunk"
            :key="salesUnit.id"
            class="sales-unit-item"
            :class="{isSelected: isSelected(salesUnit)}"
            @click="onSelectSalesUnit(salesUnit)"
          >
            <p class="content">{{ salesUnit.name }}</p>
          </div>
        </div>
      </swiper-slide>
    </swiper>

    <template v-else>
      <div class="flex items-center justify-center w-full" style="height: 88px">
        無銷售人員
      </div>
    </template>
  </div>
</template>

<script>
import { computed, defineComponent, nextTick, onMounted, ref, watch } from 'vue'
import { useSalesPOSCreate } from '@/use/useSalesPOS'
import { passValue, sortOrder } from '@/utils/helper'
import { chunk, map, includes, filter, get, find, isNull } from 'lodash'
import { Swiper, SwiperSlide, SwiperCore } from 'swiper-vue2'
import { Navigation } from 'swiper/core'
import 'swiper/swiper-bundle.css'

SwiperCore.use([Navigation])
export default defineComponent({
  name: 'SalesPOSSalesUnitList',
  components: {
    Swiper,
    SwiperSlide,
  },
  setup (props, { emit }) {
    const loaded = ref(false)
    const { configData, context, getActiveCartItem, addProductToCart, sortedMenus } = useSalesPOSCreate()

    const displayData = computed(() => {
      const selectedSalesUnitGroup = context.salesUnitGroup
      const salesUnitMenus = get(configData, 'menu.salesUnitMenus', [])

      if (isNull(selectedSalesUnitGroup)) {
        const showList = filter(salesUnitMenus, (item) => item.salesUnit.SalesGroupId === null)
        return chunk(sortOrder(map(showList, 'salesUnit')), 5)
      }

      const showList = filter(salesUnitMenus, (item) => item.salesUnit.SalesGroupId === selectedSalesUnitGroup)
      return chunk(sortOrder(map(showList, 'salesUnit')), 5)
    })

    const isSelected = computed(() => {
      return (salesUnit) => {
        return context.salesUnit === salesUnit.id
      }
    })

    const refreshSwiper = () => {
      const prevBtn = document.querySelector('.sales-pos-sales-unit-list .swiper-button-prev')
      const nextBtn = document.querySelector('.sales-pos-sales-unit-list .swiper-button-next')
      const chevronRightIcon = document.createElement('span')
      chevronRightIcon.classList.add('material-icons')
      chevronRightIcon.textContent = 'chevron_right'
      chevronRightIcon.style.fontSize = '24px'
      chevronRightIcon.style.width = '24px'
      chevronRightIcon.style.color = 'var(--primary-100)'

      const chevronLefttIcon = document.createElement('span')
      chevronLefttIcon.classList.add('material-icons')
      chevronLefttIcon.textContent = 'chevron_left'
      chevronLefttIcon.style.fontSize = '24px'
      chevronLefttIcon.style.width = '24px'
      chevronLefttIcon.style.color = 'var(--primary-100)'

      // 清空 prevBtn 和 nextBtn 的內容，避免重複添加
      if (prevBtn) prevBtn.innerHTML = ''
      if (nextBtn) nextBtn.innerHTML = ''

      if (prevBtn) prevBtn.appendChild(chevronLefttIcon)
      if (nextBtn) nextBtn.appendChild(chevronRightIcon)
    }

    const onSelectSalesUnit = (salesUnit) => {
      context.salesUnit = salesUnit.id

      if (context.cart.item) {
        const target = getActiveCartItem()
        if (target.salesUnit) {
          if (!target.needUnit) {
            if (target.salesUnit === salesUnit.id) {
              target.updateSalesUnit(context.salesUnitGroup, null)
              context.salesUnit = null
              return
            } else {
              context.salesUnit = salesUnit.id
              target.updateSalesUnit(context.salesUnitGroup, salesUnit.id)
            }
          }
        }
        target.updateSalesUnit(context.salesUnitGroup, salesUnit.id)
      } else if (!context.cart.item) {
        const product = find(sortedMenus.value.product, { id: context.product })
        const curContext = passValue(context)
        const contextData = {
          categoryGroup: curContext.categoryGroup,
          category: curContext.category,
          productGroup: curContext.productGroup,
          salesUnitGroup: curContext.salesUnitGroup,
          salesUnit: curContext.salesUnit,
        }
        addProductToCart({
          ...product,
          ...contextData,
        })
      }
    }

    watch(displayData, async () => {
      loaded.value = false
      await nextTick()
      setTimeout(() => {
        refreshSwiper()
      }, 0)
      loaded.value = true
    })

    onMounted(async () => {
      console.log('here')
      loaded.value = false
      await nextTick()
      setTimeout(() => {
        refreshSwiper()
      }, 0)
      loaded.value = true
    })

    return { context, displayData, configData, loaded, onSelectSalesUnit, isSelected }
  },
})
</script>

<style lang="postcss" scoped>
.sales-pos-sales-unit-list {
  @apply bg-primary-10 rounded-[6px] py-[12px];
}
.sales-unit-list-chunk {
  @apply grid grid-cols-5 gap-[12px] px-[32px];
}

.sales-unit-item {
  @apply bg-gray-20 rounded-[6px] h-[88px];
  @apply flex items-center justify-center text-center;
  @apply select-none cursor-pointer;
  @apply border-[2px] border-transparent;

  .content {
    @apply p-[6px] overflow-hidden h-full;
    align-content: center;
  }
}

.isSelected {
  @apply border-primary-100 border-[2px] bg-white text-primary-100;
}

::v-deep(.swiper-button-prev) {
  @apply left-0 top-[50%] cursor-pointer;
  @apply w-[24px] h-[24px] rounded-[6px];
}
::v-deep(.swiper-button-next) {
  @apply right-0 top-[50%] cursor-pointer;
  @apply w-[24px] h-[24px] rounded-[6px];
}
::v-deep(.swiper-button-prev::after) {
  content: none;
}
::v-deep(.swiper-button-next::after) {
  content: none;
}
</style>
