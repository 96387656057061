<template>
  <div class="sales-pos-item-edit-tab">
    <SalesPOSCategoryGroupTab v-show="showControl.categoryGroup" ref="categoryGroupTabRef" />
    <SalesPOSCategoryTab ref="categoryTabRef" />
    <SalesPOSProductGroupTab v-show="showControl.productGroup" ref="productGroupTabRef" />
    <SalesPOSAppointmentItemList v-show="showControl.appointmentItemList" class="flex-1" />
    <SalesPOSProductList v-show="showControl.productList" class="flex-1" />
    <SalesPOSSalesUnitGroupTab />
    <SalesPOSSalesUnitList ref="salesUnitListRef" />
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, provide, ref } from 'vue'
import SalesPOSCategoryGroupTab from './SalesPOSCategoryGroupTab.vue'
import SalesPOSCategoryTab from './SalesPOSCategoryTab.vue'
import SalesPOSProductGroupTab from './SalesPOSProductGroupTab.vue'
import SalesPOSSalesUnitGroupTab from './SalesPOSSalesUnitGroupTab.vue'
import SalesPOSProductList from './SalesPOSProductList.vue'
import SalesPOSSalesUnitList from './SalesPOSSalesUnitList.vue'
import SalesPOSAppointmentItemList from './SalesPOSAppointmentItemList.vue'
import { useSalesPOSCreate } from '@/use/useSalesPOS'
import { includes, filter, get } from 'lodash'

export default defineComponent({
  name: 'SalesPOSItemEditTab',
  components: {
    SalesPOSProductList,
    SalesPOSCategoryGroupTab,
    SalesPOSCategoryTab,
    SalesPOSProductGroupTab,
    SalesPOSSalesUnitGroupTab,
    SalesPOSSalesUnitList,
    SalesPOSAppointmentItemList,
  },
  setup (props, { emit }) {
    const { context } = useSalesPOSCreate()

    const categoryGroupTabRef = ref(null)
    const categoryTabRef = ref(null)
    const productGroupTabRef = ref(null)
    const salesUnitListRef = ref(null)

    const showControl = computed(() => {
      const controls = {
        appointmentItemList: context.category === 'appointment',
        productList: context.category !== 'appointment',
        categoryGroup: true,
        productGroup: true,
      }

      if (!get(filter(get(categoryGroupTabRef.value, 'displayTabs'), (tab) => tab.key), 'length')) {
        controls.categoryGroup = false
      }

      if (includes(['useClassTicketRecord', 'appointment'], context.category)) {
        controls.productGroup = false
      } else {
        const availableTabs = filter(get(productGroupTabRef.value, 'displayTabs'), (tab) => tab.key)
        if (!availableTabs.length) {
          controls.productGroup = false
        }
      }

      return controls
    })

    provide('categoryGroupTabRef', categoryGroupTabRef)
    provide('salesUnitListRef', salesUnitListRef)

    onMounted(() => {
      console.log('mounted')
    })

    return { showControl, categoryGroupTabRef, categoryTabRef, productGroupTabRef, salesUnitListRef }
  },
})
</script>

<style lang="postcss" scoped>
.sales-pos-item-edit-tab {
  @apply w-full flex flex-col gap-[12px];
}
</style>
