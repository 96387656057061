<template>
  <div class="flex items-center" style="gap: 6px">
    <p class="font-bold text-normal text-gray-100 flex-shrink-0 select-none">
      {{ displayText.tabTitle }}
    </p>
    <HorizontalTabs
      v-show="displayTabs.length"
      class="w-full"
      :tabs="displayTabs"
      :value.sync="context.category"
      type="text"
      @select="onSelectTab"
    />
  </div>
</template>

<script>
import { computed, defineComponent, inject, ref } from 'vue'
import HorizontalTabs from '@/components/HorizontalTabs.vue'
import { map, get, isNull } from 'lodash'
import { useSalesPOSCreate } from '@/use/useSalesPOS'
import { usePermissions } from '@/use/permissions'

export default defineComponent({
  name: 'SalesPOSCategoryTab',
  components: {
    HorizontalTabs,
  },
  setup (props, { emit }) {
    const { context, filterProductGroupMenu, filterCategoryMenu, configData } = useSalesPOSCreate()
    const { checkAction } = usePermissions()
    const categoryGroupTabRef = inject('categoryGroupTabRef')

    // !! 顯示的類別選項
    const displayTabs = computed(() => {
      const showList = filterCategoryMenu(context.categoryGroup)
      showList.push({ name: '其他', id: null })

      if (!get(categoryGroupTabRef.value, 'displayTabs.length')) {
        // > 沒有類別群組
        if (get(context.member, 'id')) {
          if (!isNull(configData.consumerData.classTicketRecords)) {
            showList.unshift({ name: '使用堂票', id: 'useClassTicketRecord' })
          }
          if (checkAction('admin.appointmentReservation.page')) {
            showList.unshift({ name: '今日預約', id: 'appointment' })
          }
        }
      } else {
        // > 有類別群組
        const categoryGroup = context.categoryGroup
        // 當類別群組為 "其他"
        if (!categoryGroup) {
          if (get(context.member, 'id')) {
            if (!isNull(configData.consumerData.classTicketRecords)) {
              showList.unshift({ name: '使用堂票', id: 'useClassTicketRecord' })
            }
            if (checkAction('admin.appointmentReservation.page')) {
              showList.unshift({ name: '今日預約', id: 'appointment' })
            }
          }
        }
      }

      return map(showList, (item) => ({
        label: item.name,
        key: item.id,
      }))
    })

    const displayText = computed(() => {
      const text = {
        tabTitle: '產品類別',
      }
      return text
    })

    const onSelectTab = () => {
      context.productGroup = get(filterProductGroupMenu(context.category), '[0].id', null)
    }

    return {
      context,
      displayTabs,
      displayText,
      onSelectTab,
      categoryGroupTabRef,
    }
  },
})
</script>

<style lang="postcss" scoped>
::v-deep(.el-tabs__header) {
  @apply !border-none;
}
::v-deep(.el-tabs__nav) {
  @apply !border-none;
}
::v-deep(.el-tabs__item) {
  @apply !border-none text-normal leading-[20.27px] h-auto py-[8px] text-gray-80;
  @apply bg-[#F6F7FB] rounded-[6px];
  @apply hover:text-primary-100;
}

::v-deep(.el-tabs__item.is-active) {
  @apply bg-primary-100 text-white;
  @apply !border-none;
}
</style>
