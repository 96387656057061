<template>
  <div class="horizontal-tabs">
    <div v-if="!hideArrow" class="tabs__nav tabs__nav-prev" @click="onPrev">
      <MaterialIcon color="var(--primary-100)">chevron_left</MaterialIcon>
    </div>
    <div ref="scrollAreaRef" class="scroll-area" :style="styles">
      <template v-for="tab in tabs">
        <slot name="item">
          <div
            :key="tab.key"
            class="tabs__item"
            :class="{
              'is-active': isActive(tab),
            }"
            :data-tabs-type="type"
            @click="onSelect($event, tab)"
          >
            {{ tab.label }}
          </div>
        </slot>
      </template>
    </div>
    <div v-if="!hideArrow" class="tabs__nav tabs__nav-next" @click="onNext">
      <MaterialIcon color="var(--primary-100)">chevron_right</MaterialIcon>
    </div>
  </div>
</template>

<script>
import { useVModel } from '@vueuse/core'
import { get } from 'lodash'
import { computed, defineComponent, ref } from 'vue'
export default defineComponent({
  name: 'HorizontalTabs',
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: '',
    },
    tabGap: {
      type: Number,
      default: 8,
    },
    type: {
      type: String,
      default: 'text',
    },
    hideArrow: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    const syncValue = useVModel(props, 'value', emit)
    const scrollAreaRef = ref(null)

    const isActive = computed(() => {
      return (tab) => tab.key === syncValue.value
    })

    const styles = computed(() => {
      return {
        gap: `${props.tabGap}px`,
      }
    })

    const onSelect = (e, tab) => {
      const targetEl = get(e, 'target')
      if (targetEl) targetEl.scrollIntoView()
      if (syncValue.value === tab.key) return
      syncValue.value = tab.key
      emit('select', tab)
    }

    const onPrev = () => {
      const scrollArea = scrollAreaRef.value
      if (scrollArea) {
        scrollArea.scrollBy({
          left: -scrollArea.offsetWidth,
          behavior: 'smooth',
        })
      }
    }
    const onNext = () => {
      const scrollArea = scrollAreaRef.value
      if (scrollArea) {
        scrollArea.scrollBy({
          left: scrollArea.offsetWidth,
          behavior: 'smooth',
        })
      }
    }

    return { styles, syncValue, isActive, onSelect, onPrev, onNext, scrollAreaRef }
  },
})
</script>

<style lang="postcss" scoped>
.horizontal-tabs {
  @apply flex items-center gap-[6px];
}
.scroll-area {
  @apply flex overflow-x-auto overflow-y-hidden flex-1;
  scroll-behavior: smooth;
}
.tabs__item {
  @apply text-gray-80 flex-shrink-0;
}
/* type = button */
.tabs__item[data-tabs-type='button'] {
  @apply cursor-pointer select-none bg-gray-20 border border-transparent;
  @apply px-[12px] py-[6px] rounded-[6px];
  @apply hover:text-primary-100;
  transition: all ease-in-out 0.3s;
}

.tabs__item[data-tabs-type='button'].is-active {
  @apply bg-primary-30 text-white border-primary-100 text-primary-100;
}

/* type = card */
.tabs__item[data-tabs-type='card'] {
  @apply cursor-pointer select-none;
  @apply px-[12px] py-[6px]  box-border;
  @apply border-b-[2px] border-transparent;
  @apply hover:text-primary-100;
  transition: ease-in-out 0.3s;
}

.tabs__item[data-tabs-type='card'].is-active {
  @apply bg-white border-b-[2px] border-primary-100;
}

/* type = text */
.tabs__item[data-tabs-type='text'] {
  @apply cursor-pointer select-none;
  @apply px-[10px];
  @apply hover:text-primary-100;
  @apply border-r-[1px] border-gray-80;
  transition: ease-in-out 0.3s;
}

.tabs__item[data-tabs-type='text']:last-child {
  @apply border-none;
}

.tabs__item[data-tabs-type='text'].is-active {
  @apply text-primary-100;
}

.tabs__nav {
  @apply rounded-[6px] cursor-pointer select-none;
  @apply w-[24px] h-[24px];
}

.tabs__btn {
  .is-active {
    @apply bg-primary-100 text-white;
    @apply hover:text-white;
  }
}

::-webkit-scrollbar {
  @apply h-0 w-0;
}

</style>
