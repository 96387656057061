var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"sales-pos-product-list"},[(_vm.loaded)?_c('swiper',{staticClass:"w-full h-full",attrs:{"navigation":"","pagination":"","centeredSlides":"","loop":false}},_vm._l((_vm.displayData),function(page,pageIdx){return _c('swiper-slide',{key:pageIdx},[_c('div',{staticClass:"flex flex-col",staticStyle:{"gap":"12px"}},_vm._l((_vm.chunk(page, 5)),function(row){return _c('div',{key:row[0].id,staticClass:"product-list-chunk"},_vm._l((row),function(product){return _c('div',{key:product.id,staticClass:"product-item",class:{
              isSelected: _vm.isSelected(product),
              isDisabled: _vm.isDisabled(product),
              'class-ticket-item': product.useClassTicket || product.useClassTicketRecord,
            },on:{"click":function($event){return _vm.onSelectProduct(product)}}},[(product.useClassTicket)?_c('div',{staticClass:"classTicket-availableTimes"},[_vm._v("購買堂票")]):_vm._e(),(product.useClassTicketRecord)?_c('div',{staticClass:"classTicket-availableTimes"},[_vm._v("剩餘 "+_vm._s(_vm.get(product, 'classTicketRecord.availableUseTimes', '?'))+" 次")]):_vm._e(),_c('div',{staticClass:"item-content",class:{
                'classTicket-content': product.useClassTicket || product.useClassTicketRecord,
              },staticStyle:{"margin":"6px"}},[_vm._v(" "+_vm._s(product.name)+" ")])])}),0)}),0)])}),1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }