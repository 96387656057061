<template>
  <div class="sales-pos-operate-footer">
    <div class="flex justify-between w-full">
      <div class="flex">
        <BaseElButton
          v-if="displayOperate.singleDiscount"
          class="operate-btn item-discount-btn"
          :class="{ 'disabled': disabledControl.singleDiscount }"
          testName="singleDiscount"
          :disabled="disabledControl.singleDiscount"
          @click="changeTab('singleDiscount')"
        >
          <div class="flex items-center justify-center" style="gap: 4px">
            <SVGIcon icon="common-ticket" fill="var(--success)" />
            單項折扣
          </div>
        </BaseElButton>
        <BaseElButton
          v-if="displayOperate.backToEdit"
          class="operate-btn back-edit-btn"
          testName="backToEdit"

          @click="changeTab('edit')"
        >
          <div class="flex flex-col items-center justify-center" style="gap: 4px">
            <span>返回</span>
            <span>商品頁面
            </span>
          </div>
        </BaseElButton>
        <BaseElButton
          v-if="displayOperate.removeItem"
          class="operate-btn remove-item-btn"
          :class="{ 'disabled': disabledControl.removeItem }"
          testName="goCheckout"
          :disabled="disabledControl.removeItem"
          @click="onRemoveProduct"
        >
          <div class="flex items-center justify-center" style="gap: 4px">
            <SVGIcon icon="common-trash" fill="var(--danger)" />
            單項刪除
          </div>
        </BaseElButton>
      </div>

      <BaseElButton
        class="operate-btn checkout-btn"
        testName="goCheckout"
        :class="{ 'disabled': disabledControl.goCheckout }"
        :disabled="disabledControl.goCheckout"
        @click="goCheckout"
      >
        前往結帳
      </BaseElButton>
    </div>
  </div>
</template>

<script>
import { useSalesPOSCreate } from '@/use/useSalesPOS'
import { first, reduce, filter, get } from 'lodash'

import { computed, defineComponent, inject } from 'vue'
import { useRouter } from 'vue-router/composables'
export default defineComponent({
  name: 'SalesPOSOperateFooter',
  setup (props, { emit }) {
    const posState = inject('posState')
    const router = useRouter()
    const {
      context,
      cart,
      removeProductFromCart,
      getAllSingleDiscountsPrice,
      getActiveCartItem,
      originTotalPrice,
      setActiveCartItem,
    } = useSalesPOSCreate()

    const disabledControl = computed(() => {
      const controls = {
        singleDiscount: false,
        removeItem: false,
        goCheckout: false,
      }

      if (!getActiveCartItem()) {
        controls.singleDiscount = true
        controls.removeItem = true
      }

      if (!cart.value.length) {
        controls.goCheckout = true
      }

      return controls
    })

    const displayOperate = computed(() => {
      const data = {
        singleDiscount: true,
        backToEdit: false,
        removeItem: true,
        checkout: true,
      }

      if (posState.tab === 'singleDiscount') {
        data.singleDiscount = false
        data.removeItem = false
        data.backToEdit = true
      }

      return data
    })

    const changeTab = (tab) => {
      posState.tab = tab
    }

    const onSingleDiscount = () => {
      posState.tab = 'singleDiscount'
    }

    const onRemoveProduct = () => {
      // AppointmentOrderId
      const cartItem = getActiveCartItem()
      const appointmentData = get(cartItem, 'appointmentData')

      if (appointmentData) {
        const appointmentOrderId = get(cartItem.appointmentData, 'AppointmentOrderId') // 預約訂單 ID

        // 如果刪除的是預約商品，要同時移除訂單的付款方式
        // 如果購物車中，已沒有同一筆預約訂單商品才要移除付款方式
        const orderItemCount = get(filter(cart.value, (item) => {
          return get(item, 'appointmentData.AppointmentOrderId') === appointmentOrderId
        }), 'length')

        if (orderItemCount === 1) {
          context.payments = filter(context.payments, (payment) => {
            return payment.appointmentOrderId !== appointmentOrderId
          })
        }
      }

      removeProductFromCart(context.cart.item)
      if (get(cart.value, 'length')) {
        setActiveCartItem(first(cart.value))
      }
    }

    const goCheckout = () => {
      const paidDeposit = reduce(context.payments, (sum, payment) => {
        if (payment.type === 'paidDeposit') {
          return sum + payment.price
        }
        return sum
      }, 0)
      const totalPrice = originTotalPrice.value - paidDeposit - getAllSingleDiscountsPrice()
      if (totalPrice < 0) {
        window.$message.warning('訂單金額不得為負數')
        return
      }
      router.push({ name: 'SalesPOSCheckout' })
    }

    return {
      onSingleDiscount,
      displayOperate,
      changeTab,
      onRemoveProduct,
      disabledControl,
      goCheckout,
    }
  },
})
</script>

<style lang="postcss" scoped>
.sales-pos-operate-footer {
  @apply flex items-center justify-between;
}

.operate-btn {
  @apply h-[77px] px-[37.4px] rounded-[6px] text-normal font-medium;
}

.checkout-btn {
  @apply bg-primary-100 text-white;
}

.remove-item-btn {
  @apply border-danger bg-danger-light text-danger;
}

.item-discount-btn,
.back-edit-btn {
  @apply bg-success-light text-success border border-success;
}

.disabled {
  @apply opacity-50 cursor-not-allowed ;
}
</style>
