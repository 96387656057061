<template>
  <div class="flex items-center" style="gap: 6px">
    <p class="font-bold text-normal text-gray-100 flex-shrink-0 select-none">
      {{ displayText.tabTitle }}
    </p>
    <HorizontalTabs
      v-show="displayTabs.length"
      class="w-full"
      :tabs="displayTabs"
      :value.sync="context.productGroup"
      type="button"
    />
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue'
import HorizontalTabs from '@/components/HorizontalTabs.vue'
import { useSalesPOSCreate } from '@/use/useSalesPOS'
import { map } from 'lodash'

export default defineComponent({
  name: 'SalesPOSProductGroupTab',
  components: {
    HorizontalTabs,
  },
  setup (props, { emit }) {
    const { context, filterProductGroupMenu } = useSalesPOSCreate()
    const displayTabs = computed(() => {
      if (!context.category) return []
      const showList = filterProductGroupMenu(context.category)
      const tabs = map(showList, (group) => ({
        label: group.name,
        key: group.id,
      }))
      tabs.push({ label: '其他', key: null })
      return tabs
    })

    const displayText = computed(() => {
      const text = {
        tabTitle: '產品群組',
      }
      return text
    })

    return {
      context,
      displayTabs,
      displayText,
    }
  },
})
</script>

<style lang="postcss" scoped>
::v-deep(.el-tabs__header) {
  @apply !border-none;
}
::v-deep(.el-tabs__nav) {
  @apply !border-none;
}
::v-deep(.el-tabs__item) {
  @apply border-l-[1px] !text-sub leading-[20.27px] h-auto;
  @apply hover:text-primary-50;
}

::v-deep(.el-tabs__item.is-active) {
  @apply text-primary-100;
}
</style>
