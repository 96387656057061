<template>
  <div
    class="sales-pos-product-list"
  >
    <swiper
      v-if="loaded"
      class="w-full h-full"
      navigation
      pagination
      centeredSlides
      :loop="false"
    >
      <swiper-slide v-for="(page, pageIdx) in displayData" :key="pageIdx">
        <div class="flex flex-col" style="gap: 12px">
          <div v-for="row in chunk(page, 5)" :key="row[0].id" class="product-list-chunk">
            <div
              v-for="product in row"
              :key="product.id"
              class="product-item"
              :class="{
                isSelected: isSelected(product),
                isDisabled: isDisabled(product),
                'class-ticket-item': product.useClassTicket || product.useClassTicketRecord,
              }"
              @click="onSelectProduct(product)"
            >
              <div v-if="product.useClassTicket" class="classTicket-availableTimes">購買堂票</div>
              <div v-if="product.useClassTicketRecord" class="classTicket-availableTimes">剩餘 {{ get(product, 'classTicketRecord.availableUseTimes', '?') }} 次</div>
              <div
                class="item-content"
                :class="{
                  'classTicket-content': product.useClassTicket || product.useClassTicketRecord,
                }"
                style="margin: 6px"
              >
                {{ product.name }}
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { useSalesPOSCreate } from '@/use/useSalesPOS'
import { passValue, sortOrder } from '@/utils/helper'
import { useWindowSize } from '@vueuse/core'
import { first, isNull, isEmpty, chunk, map, includes, filter, get, find } from 'lodash'

import { Swiper, SwiperSlide, SwiperCore } from 'swiper-vue2'
import { Navigation, Pagination } from 'swiper/core'
import 'swiper/swiper-bundle.css'

import { computed, defineComponent, inject, nextTick, onMounted, ref, watch } from 'vue'

SwiperCore.use([Navigation, Pagination])
export default defineComponent({
  name: 'SalesPOSProductList',
  components: {
    Swiper,
    SwiperSlide,
  },
  setup (props, { emit }) {
    const salesUnitListRef = inject('salesUnitListRef')
    const loaded = ref(false)
    const { width: windowWidth } = useWindowSize()
    const { cart, configData, context, addProductToCart, computedUsedResource } = useSalesPOSCreate()

    const displayData = computed(() => {
      const selectedCategoryGroup = context.categoryGroup
      const selectedCategory = context.category
      const selectedProductGroup = context.productGroup

      if (selectedCategory === 'useClassTicketRecord') {
        return chunk(sortOrder(classTicketRecortdProducts()), 15)
      }

      const categoryMenus = get(configData, 'menu.salesCategoryMenus', [])
      const productMenus = get(configData, 'menu.salesProductMenus', [])

      let filterProductList = get(configData, 'menu.salesProductMenus', [])

      // 目前類別群組下選擇的類別
      const categorys = filter(categoryMenus, { salesCategory: { SalesGroupId: selectedCategoryGroup } })
      const activeCategory = find(categorys, { salesCategory: { id: selectedCategory } })

      // 目前類別下的產品
      const activeCategoryProductIds = get(activeCategory, 'salesProductIds', [])
      let categoryProducts = filter(productMenus, (item) => includes(activeCategoryProductIds, get(item, 'salesProduct.id')))
      if (selectedCategory === null) {
        categoryProducts = filter(productMenus, (item) => isEmpty(item.salesCategoryIds))
      }

      // 過濾產品群組的商品
      filterProductList = filter(categoryProducts, { salesProduct: { SalesGroupId: selectedProductGroup } })

      filterProductList = filter(filterProductList, (item) => {
        if (get(item, 'salesProduct.useClassTicket')) {
          if (isNull(get(item.salesProduct, 'ClassTicket'))) return false
        }
        return true
      })

      return chunk(sortOrder(map(filterProductList, 'salesProduct')), 15)
    })

    // ?? 是否被選擇
    const isSelected = computed(() => {
      return (product) => {
        return product.id === context.product
      }
    })

    const isDisabled = computed(() => {
      return (product) => {
        if (get(product, 'useClassTicketRecord')) {
          const classTicketRecordId = get(product, 'classTicketRecord.id')
          const availableUseTimes = get(product, 'classTicketRecord.availableUseTimes')
          const preUsedTimes = get(computedUsedResource.value.classTicketRecord, classTicketRecordId, 0)
          const preUsedTimes2 = get(computedUsedResource.value.classTicket, classTicketRecordId, 0) // TO REFACTOR
          if (preUsedTimes + preUsedTimes2 >= availableUseTimes) {
            return true
          }
        }
        return false
      }
    })

    const classTicketRecortdProducts = () => {
      const memberClassTicketRecords = get(configData.consumerData, 'classTicketRecords', [])
      return map(memberClassTicketRecords, (item) => {
        return {
          type: 'classTicketRecordUse',
          ClassTicket: null,
          SalesGroupId: null,
          coinAmount: 0,
          defaultPrice: 0,
          id: item.id,
          name: item.name,
          needUnit: false,
          order: 0,
          useClassTicket: false,
          useClassTicketRecord: true,
          useCoin: false,
          classTicketRecord: item,
        }
      })
    }

    // => 當選擇商品時
    const onSelectProduct = (product) => {
      if (isDisabled.value(product)) return
      const curContext = passValue(context)
      const contextData = {
        categoryGroup: curContext.categoryGroup,
        category: curContext.category,
        productGroup: curContext.productGroup,
        salesUnitGroup: curContext.salesUnitGroup,
        salesUnit: curContext.salesUnit,
      }

      context.product = product.id
      if (!product.needUnit) {
        delete contextData.salesUnit
        delete contextData.salesUnitGroup
        context.salesUnit = null
      } else if (product.needUnit) {
        if (!curContext.salesUnit) {
          context.salesUnit = get(first(first(salesUnitListRef.value.displayData)), 'id')
          contextData.salesUnit = get(first(first(salesUnitListRef.value.displayData)), 'id')
        }
      }

      addProductToCart({
        ...product,
        ...contextData,
      })
    }

    const refreshSwiper = () => {
      const prevBtn = document.querySelector('.sales-pos-product-list .swiper-button-prev')
      const nextBtn = document.querySelector('.sales-pos-product-list .swiper-button-next')
      const chevronRightIcon = document.createElement('span')
      chevronRightIcon.classList.add('material-icons')
      chevronRightIcon.textContent = 'chevron_right'
      chevronRightIcon.style.fontSize = '24px'
      chevronRightIcon.style.width = '24px'
      chevronRightIcon.style.color = 'var(--primary-100)'

      const chevronLefttIcon = document.createElement('span')
      chevronLefttIcon.classList.add('material-icons')
      chevronLefttIcon.textContent = 'chevron_left'
      chevronLefttIcon.style.fontSize = '24px'
      chevronLefttIcon.style.width = '24px'
      chevronLefttIcon.style.color = 'var(--primary-100)'

      // 清空 prevBtn 和 nextBtn 的內容，避免重複添加
      if (prevBtn) prevBtn.innerHTML = ''
      if (nextBtn) nextBtn.innerHTML = ''

      if (prevBtn) prevBtn.appendChild(chevronLefttIcon)
      if (nextBtn) nextBtn.appendChild(chevronRightIcon)
    }

    watch(displayData, async () => {
      loaded.value = false
      await nextTick()
      setTimeout(() => {
        refreshSwiper()
      }, 0)
      loaded.value = true
    })

    onMounted(async () => {
      await nextTick()
      refreshSwiper()
      loaded.value = true
    })

    // TOFIX Multiple line truncate

    return {
      cart,
      context,
      displayData,
      configData,
      onSelectProduct,
      loaded,
      isSelected,
      get,
      windowWidth,
      isDisabled,
      chunk,
      computedUsedResource,
    }
  },
})
</script>

<style lang="postcss" scoped>
.sales-pos-product-list {
  @apply bg-primary-10 rounded-[6px] py-[12px];
  /* @apply grid grid-cols-5 grid-rows-3 gap-[12px]; */
}

.product-list-chunk {
  @apply gap-[12px] px-[32px] grid grid-cols-5;
  @apply w-full h-full;
}

.product-item {
  @apply bg-gray-20 rounded-[6px] overflow-hidden;
  @apply flex items-center justify-center text-center;
  @apply select-none cursor-pointer;
  @apply border-[2px] border-transparent;
  @apply h-[120px] ;
}

.class-ticket-item {
  @apply flex flex-col justify-between;
}

.classTicket-availableTimes {
  @apply bg-primary-100 text-white w-full p-[6px];
}

.classTicket-content {
  @apply flex-1 h-auto;
}

.isSelected {
  @apply border-primary-100 border-[2px] bg-white text-primary-100;
}

.isDisabled {
  @apply opacity-50 cursor-not-allowed;
}

.item-content {
  @apply overflow-hidden h-full;
  align-content: center;
}

@media screen and (max-height: 768px) {
  .product-item {
    @apply h-[90px];
  }
}

::v-deep(.swiper-button-prev) {
  @apply left-0 top-[50%] cursor-pointer;
  @apply w-[24px] h-[24px] rounded-[6px];
}
::v-deep(.swiper-button-next) {
  @apply right-0 top-[50%] cursor-pointer;
  @apply w-[24px] h-[24px] rounded-[6px];
}

::v-deep(.swiper-button-prev::after) {
  content: none;
}
::v-deep(.swiper-button-next::after) {
  content: none;
}

::v-deep(.swiper-pagination-bullet) {
  @apply bg-primary-100;
}
</style>
